export default {
	cinema: {
		address: 'ул. Калинина, 2',
		place: 'РЦ "Кин-дза-дза"',
		phoneNumbers: ['+7(34397) 3-16-86', '+7(34397) 33-900'],
		facebook: null,
		instagram: null,
		vk: 'https://vk.com/cristallcinema',
		twitter: null,
		odnoklassniki: null,
		youtube: null,
		app: {
			appstore: null,
			googleplay: null,
		},
		payment: {
			active: true,
			card: ['mir', 'visa', 'mastercard', 'maestro', 'sbp', 'sberpay'],
		},
		maps: 'https://yandex.ru/maps/-/CGh6nS4r',
		selectButton: {
			options: [],
			selectedOptionValue: null,
		},
		soonPageIsEnabled: false,
		saleRules: false,
		details: false,
	},
	feedback: true,
};
